const initNavigation = () => {
  const init = () => {
    const main_header = document.getElementById("main-header")
    const main_content = document.getElementById("main-content")

    const toggleMenuHandler = () => {
      document
        .querySelector(".header__menu")
        .classList.toggle("header__menu--show")
    }

    const goToAnchorHandler = section => {
      return event => {
        event.preventDefault()
        goToAnchor(section)
      }
    }

    main_content.onscroll = () => {
      if (main_content.scrollTop >= 5) {
        main_header.classList.add("header--fixed")
      } else {
        main_header.classList.remove("header--fixed")
      }
    }
    ;[
      document.querySelector(".header__menu-button"),
      document.querySelector(".header__menu-overlay"),
      ...document.querySelectorAll(".header__menu-item a"),
      ...document.querySelectorAll(".link__contact"),
    ].forEach(e => {
      e.addEventListener("click", toggleMenuHandler)
    })
    ;[
      ...document.querySelectorAll(".header__menu-item a"),
      ...document.querySelectorAll(".link__contact"),
    ].forEach(element => {
      const section = element.getAttribute("href")
      if (section.startsWith("#")) {
        element.addEventListener("click", goToAnchorHandler(section))
      }
    })
  }

  const goToAnchor = section => {
    const main_content = document.getElementById("main-content")
    const scroll_target = document.querySelector(section).offsetTop - 80
    main_content.scroll({
      top: scroll_target,
      behavior: "smooth",
    })
  }

  return {
    init,
    goToAnchor,
  }
}

export default () => {
  return initNavigation()
}
